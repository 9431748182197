import React from 'react'
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }
  
  var settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    infinite: true,
    speed: 800,
    arrows: true, // Corrected typo here
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true, // Enable autoplay
  autoplaySpeed: 2000, // Set autoplay speed in milliseconds (e.g., 3000ms = 3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true, // Corrected typo here
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true, // Corrected typo here
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true, // Corrected typo here
        }
      }
    ]
};


const Productscarouselo2 = () => {
  return (
    <>
     <div className="container">
        <div className="row">
            <div className="col-lg-12 p-0">
            <Slider {...settings}>
      

      {/* itmes start */}
    <div className='items'>
    <img src="../products/2.png" alt className="img-fluid" />
    </div>
     {/* itmes end */}

        {/* itmes start */}
    <div className='items'>
    <img src="../products/3.png" alt className="img-fluid" />
    </div>
     {/* itmes end */}
     
  </Slider>
            </div>
        </div>
     </div>
    </>
  )
}

export default Productscarouselo2;

